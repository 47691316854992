<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            icon
        >
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="activate">
          <v-list-item-title>{{ $t('generic.lang_activateAll') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="deactivate">
          <v-list-item-title>{{ $t('generic.lang_disableAll') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Loading Dialog -->
    <v-dialog persistent max-width="400" :value="loading">
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular style="margin-top: 20px;" width="3" size="50" indeterminate color="primary"></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditItemExtrasActivateButton",

  props: {
    garnishTab: Object,
    itemgroupID: Number
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    activate() {
      this.$emit("activate-all", {
        garnishTab: this.garnishTab,
        itemgroupID: this.itemgroupID
      });
    },
    deactivate() {
      this.$emit("deactivate-all", {
        garnishTab: this.garnishTab,
        itemgroupID: this.itemgroupID
      });
    }
  }
}
</script>